<template>
  <router-view/>
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  background: #F3F4F9;
}
.dettagliSfondo {
  height: 100%;
  background-image: url('./assets/images/head_desktop.jpg');
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.roundA td:not(:first-child) {
  background: #006636;
  color: #FFFFFF;
}
.roundB td:not(:first-child) {
  background: #bb7cb3;
  color: #FFFFFF;
}
.roundC td:not(:first-child) {
  background: #f7a600;
  color: #FFFFFF;
}
.roundD td:not(:first-child) {
  background: #94c23d;
  color: #FFFFFF;
}
.roundE td:not(:first-child) {
  background: #e6224f;
  color: #FFFFFF;
}
.roundF td:not(:first-child) {
  background: #6a144c;
  color: #FFFFFF;
}
.roundG td:not(:first-child) {
  background: #4bbdcf;
  color: #FFFFFF;
}
.roundH td:not(:first-child) {
  background: gold;
  color: #FFFFFF;
}

.impiantoBg {
  .impiantoBox {
    width: 100px;
    height: 72px;
    margin: auto;
    .impiantoImg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
/* .roundnull td:not(:first-child) {
  background: lightgray;
} */
th {
  padding: 10px 0;
  border: 1px solid #000;
  color: #000;
  width: calc(100% / 8);
}
th:first-child {
  border-top-left-radius: 25px; 
}
th:last-child {
  border-top-right-radius: 25px;
}
th:not(:first-child) {
  border-left: none;
}
.groupStageRound {
  td {
    border-bottom: 1px dashed #808080;
    border-right: 1px dashed #808080;
  }
} 
td {
  padding: 25px 0;
  border-bottom: 1px dashed #808080;
  border-right: 1px dashed #808080;
}
td:first-child {
  border-left: 1px solid #000;
}
td:last-child {
  border-right: 1px solid #000;
}
tr:last-child {
  td {
    border-bottom: 1px solid #000;
  }
  td:first-child {
    border-bottom-left-radius: 25px;
  }
  td:last-child {
    border-bottom-right-radius: 25px;
  }
}
</style>