import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import ContentPage from '../views/ContentPage.vue'
import debugPage from '../views/debugPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingPage
  },
  {
    path: '/mondiali_backoffice/:conto/:token',
    name: 'mondialibackoffice',
    component: ContentPage,
    params: true
  },
  {
    path: '/mondiali_backoffice_debug/:conto/:token/:debug',
    name: 'mondialibodebug',
    component: debugPage,
    params: true
  }
  /* {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/AboutView.vue')//webpackChunkName: "about"
    }
  } */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
