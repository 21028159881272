<template>
    <div class="dettagliSfondo">
        <div v-if="loading">
            <ProgressSpinner />
        </div>
        <div v-else class="contenutoPagina">
            <div class="userPredictionSection py-5">
                <div class="quitBtn">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" @click="back()" />
                </div>
                <div class="logosContainer">
                    <div class="logoBox">
                        <img src="../assets/images/logomondiali.svg" alt="logo 1x2" class="logoImg">
                    </div>
                    <div class="logoBox">
                        <img src="../assets/images/logosisalverde.png" alt="logo 1x2" class="logoImg">
                    </div>
                    <div class="logoBox">
                        <img src="../assets/images/logo1x2.png" alt="logo 1x2" class="logoImg">
                    </div>
                </div>
                <div v-if="pronostico" class="textContentContainer">
                    <div class="title">2022 MATCH SCHEDULE</div>
                    <div class="conto">{{codiceConto}}</div>
                    <div class="totalePunti">TOTALE PUNTI: {{ uBonusTotPoints }} SU 5.000</div>
                    <div class="pronosticiCorretti">PRONOSTICI: {{ uPronoRight }} SU 63</div>
                </div>
                <div v-else class="textContentContainer">
                    <div class="title">2022 WORLD CUP BACKOFFICE</div>
                    <div class="conto">{{codiceConto}}</div>
                </div>
                <div v-if="!isPronoCompleted" class="pronoNotCompleteBox">
                    <div class="noPronoMessage">Pronostico non completato</div>
                </div>
                <div class="customWrapper">
                    <table v-show="pronostico" class="groupStageRound">
                        <thead>
                            <tr>
                                <th>GIRONI</th>
                                <th>MATCH</th>
                                <th>GIORNO</th>
                                <th>TEAM 1</th>
                                <th>TEAM 2</th>
                                <th>PRONOSTICO</th>
                                <th>RISULTATO</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr v-for="(partita, index) in scheduleGironi" :key="index" :class="'round'+partita.round">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                        <!-- {{partita.mode}} -->
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchId}}
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchDateFormatted}}
                                    </div>
                                </td>
                                <td>
                                    <div class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team1}}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team2}}
                                        </div>
                                    </div> 
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="pronosContainer">
                                        <div class="pronoBox" :class="{active: partita.userPredictedResult == '1', corretto: setClass(1, partita.userPredictedResult, partita.result, partita.matchId).corretto, sbagliato: setClass(1, partita.userPredictedResult, partita.result, partita.matchId).sbagliato, risultatoEsatto: setMatchResultClass(1, partita.result)}">
                                            <div class="prono">1</div>
                                        </div>
                                        <div class="pronoBox" :class="{active: partita.userPredictedResult == 'x', corretto: setClass('X', partita.userPredictedResult.toUpperCase(), partita.result, partita.matchId).corretto, sbagliato: setClass('X', partita.userPredictedResult.toUpperCase(), partita.result, partita.matchId).sbagliato, risultatoEsatto: setMatchResultClass('X', partita.result)}">
                                            <div class="prono">X</div>
                                        </div>
                                        <div class="pronoBox" :class="{active: partita.userPredictedResult == '2', corretto: setClass(2, partita.userPredictedResult, partita.result, partita.matchId).corretto, sbagliato: setClass(2, partita.userPredictedResult, partita.result, partita.matchId).sbagliato, risultatoEsatto: setMatchResultClass(2, partita.result)}">
                                            <div class="prono">2</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.result"><b>---</b></div>
                                    <div class="singlePronoBox">
                                        <div class="prono">
                                            {{ partita.result }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!isPronoCompleted">---</div>
                                    <div v-else>
                                        <div v-if="!partita.userPredictedResult">
                                            <b>0</b>
                                        </div>
                                        <div v-else>
                                            <div v-if="!partita.result"><b>---</b></div>
                                            <div v-else>
                                                <div v-if="partita.userPredictedResult.toUpperCase() == partita.result"><b>50</b></div>
                                                <div v-else><b>0</b></div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronostico" class="round16">
                        <thead>
                            <tr>
                                <th>OTTAVI</th>
                                <th>MATCH</th>
                                <th>GIORNO</th>
                                <th>TEAM 1</th>
                                <th>TEAM 2</th>
                                <th>PRONOSTICO</th>
                                <th>AI QUARTI</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr v-for="(partita, index) in scheduleRound16" :key="index" :class="'round'+partita.round">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                        <!-- {{partita.mode}} -->
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchId}}
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchDateFormatted}}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else>
                                        <div class="teamBox">
                                            <div class="bandiera">
                                                <img :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            </div>
                                            <div>
                                                {{partita.team1}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else>
                                        <div class="teamBox">
                                            <div class="bandiera">
                                                <img :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            </div>
                                            <div>
                                                {{partita.team2}}
                                            </div>
                                        </div>
                                    </div> 
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else>
                                        <div class="teamBox">
                                            <div class="bandiera">
                                                <img v-if="partita.userPredictedResult == '1'" :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                                <img v-else :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            </div>
                                            <div v-if="partita.userPredictedResult == '1'">
                                                {{partita.team1}}
                                            </div>
                                            <div v-else>
                                                {{partita.team2}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.result">---</div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="knockoutStagesWT(partita).bandiera" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{ knockoutStagesWT(partita).team }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!isPronoCompleted">---</div>
                                    <div v-else>
                                        <div v-if="!partita.result">---</div>
                                        <div v-else>
                                            <div v-if="partita.result == partita.userPredictedResult"><b>100</b></div>
                                            <div v-else><b>0</b></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronostico" class="quarter">
                        <thead>
                            <tr>
                                <th>QUARTI</th>
                                <th>MATCH</th>
                                <th>GIORNO</th>
                                <th>TEAM 1</th>
                                <th>TEAM 2</th>
                                <th>PRONOSTICO</th>
                                <th>ALLE SEMI</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr v-for="(partita, index) in scheduleQuarter" :key="index" :class="'round'+partita.round">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                        <!-- {{partita.mode}} -->
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchId}}
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchDateFormatted}}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else>
                                        <div class="teamBox">
                                            <div class="bandiera">
                                                <img :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            </div>
                                            <div>
                                                {{partita.team1}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else>
                                        <div class="teamBox">
                                            <div class="bandiera">
                                                <img :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            </div>
                                            <div>
                                                {{partita.team2}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else>
                                        <div class="teamBox">
                                            <div class="bandiera">
                                                <img v-if="partita.userPredictedResult == '1'" :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                                <img v-else :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            </div>
                                            <div v-if="partita.userPredictedResult == '1'">
                                                {{partita.team1}}
                                            </div>
                                            <div v-else>
                                                {{partita.team2}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.result">---</div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="knockoutStagesWT(partita).bandiera" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{ knockoutStagesWT(partita).team }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!isPronoCompleted">---</div>
                                    <div v-else>
                                        <div v-if="!partita.result">---</div>
                                        <div v-else>
                                            <div v-if="partita.result == partita.userPredictedResult"><b>100</b></div>
                                            <div v-else><b>0</b></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronostico" class="semis">
                        <thead>
                            <tr>
                                <th>SEMIFINALI</th>
                                <th>MATCH</th>
                                <th>GIORNO</th>
                                <th>TEAM 1</th>
                                <th>TEAM 2</th>
                                <th>PRONOSTICO</th>
                                <th>FINALISTE</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr v-for="(partita, index) in scheduleSemis" :key="index" :class="'round'+partita.round">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                        <!-- {{partita.mode}} -->
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchId}}
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchDateFormatted}}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team1}}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team2}}
                                        </div>
                                    </div> 
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img v-if="partita.userPredictedResult == '1'" :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            <img v-else :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div v-if="partita.userPredictedResult == '1'">
                                            {{partita.team1}}
                                        </div>
                                        <div v-else>
                                            {{partita.team2}}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.result">---</div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="knockoutStagesWT(partita).bandiera" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{ knockoutStagesWT(partita).team }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!isPronoCompleted">---</div>
                                    <div v-else>
                                        <div v-if="!partita.result">---</div>
                                        <div v-else>
                                            <div v-if="partita.result == partita.userPredictedResult"><b>300</b></div>
                                            <div v-else><b>0</b></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <table class="finalB">
                        <thead>
                            <tr>
                                <th>FINALE B</th>
                                <th>MATCH</th>
                                <th>GIORNO</th>
                                <th>TEAM 1</th>
                                <th>TEAM 2</th>
                                <th>PRONOSTICO</th>
                                <th>TERZA</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr v-for="(partita, index) in scheduleFinalB" :key="index" :class="'round'+partita.round">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchId}}
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchDateFormatted}}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team1}}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team2}}
                                        </div>
                                    </div> 
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img v-if="partita.userPredictedResult == '1'" :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            <img v-else :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div v-if="partita.userPredictedResult == '1'">
                                            {{partita.team1}}
                                        </div>
                                        <div v-else>
                                            {{partita.team2}}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.result">---</div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="knockoutStagesWT(partita).bandiera" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{ knockoutStagesWT(partita).team }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.result"><b>NO</b></div>
                                    <div v-else><b>NO</b></div>
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
                    <table v-show="pronostico" class="finalA">
                        <thead>
                            <tr>
                                <th>FINALE</th>
                                <th>MATCH</th>
                                <th>GIORNO</th>
                                <th>TEAM 1</th>
                                <th>TEAM 2</th>
                                <th>PRONOSTICO</th>
                                <th>CAMPIONE</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr v-for="(partita, index) in scheduleFinalA" :key="index" :class="'round'+partita.round">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                        <!-- {{partita.mode}} -->
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchId}}
                                    </div>
                                </td>
                                <td>
                                    <div class="">
                                        {{partita.matchDateFormatted}}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team1}}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team2}}
                                        </div>
                                    </div> 
                                </td>
                                <td>
                                    <div v-if="!partita.userPredictedResult">
                                        <b>NESSUN PRONOSTICO</b>
                                    </div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img v-if="partita.userPredictedResult == '1'" :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                            <img v-else :src="require('../assets/flag/'+partita.team2Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div v-if="partita.userPredictedResult == '1'">
                                            {{partita.team1}}
                                        </div>
                                        <div v-else>
                                            {{partita.team2}}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!partita.result">---</div>
                                    <div v-else class="teamBox">
                                        <div class="bandiera">
                                            <img :src="knockoutStagesWT(partita).bandiera" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{ knockoutStagesWT(partita).team }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!isPronoCompleted">---</div>
                                    <div v-else>
                                        <div v-if="!partita.result">---</div>
                                        <div v-else>
                                            <div v-if="partita.result == partita.userPredictedResult"><b>800</b></div>
                                            <div v-else><b>0</b></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-show="!pronostico" class="noPronoBox">
                        <div class="noPronoMessage">{{ noPronoMessage }}</div>
                    </div>
                    <table v-if="teamBonus" class="BONUSSQUADRA">
                        <thead>
                            <tr>
                                <th>BONUS SQUADRA</th>
                                <th>SQUADRA</th>
                                <th>ATTIVO DAL</th>
                                <th>CLEAN SHEET</th>
                                <th>VITTORIA</th>
                                <th>GOAL</th>
                                <th>PROGRESSO</th>
                                <th>BONUS</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr class="roundnull">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                    </div>
                                </td>
                                <td>
                                    <div class="teamBox">
                                        <div class="bandiera">
                                            <img :src="require('../assets/flag/'+teamBonus.teamId+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            <b>{{ teamBonus.team }}</b>
                                        </div>
                                    </div>
                                </td>
                                <td><b>{{ teamBonus.last_update_formatted }}</b></td>
                                <td>
                                    <div class="bsBox">
                                        <div><b>{{ teamBonus.cleanSheets }}</b></div>
                                        <div><small>x {{ teamBonus.legenda[0]['1 clean sheet'] }}</small></div>
                                    </div>
                                </td>
                                <td>
                                    <div class="bsBox">
                                        <div><b>{{ teamBonus.wins }}</b></div>
                                        <div><small>x {{ teamBonus.legenda[2]['1 vittoria'] }}</small></div>
                                    </div>
                                </td>
                                <td>
                                    <div class="bsBox">
                                        <div><b>{{ teamBonus.goals }}</b></div>
                                        <div><small>x {{ teamBonus.legenda[1]['1 gol'] }}</small></div>
                                    </div>
                                </td>
                                <td>
                                    <b>{{ teamBonus.perc }}%</b>
                                </td>
                                <td>
                                    <div v-if="teamBonus.perc != 100" class="bsBox">
                                        <div><b><small>{{ teamBonus.userBonus.label }}</small></b></div>
                                        <div class="px-2"><small>Ha diritto al bonus con progresso al 100%</small></div>
                                    </div>
                                    <b v-else>{{ teamBonus.userBonus.label }}</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-else class="BONUSSQUADRA">
                        <thead>
                            <tr>
                                <th>BONUS SQUADRA</th>
                                <th>SQUADRA</th>
                                <th>ATTIVO DAL</th>
                                <th>CLEAN SHEET</th>
                                <th>VITTORIA</th>
                                <th>GOAL</th>
                                <th>PROGRESSO</th>
                                <th>BONUS</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr class="roundnull">
                                <td class="impiantoBg">
                                    <div class="impiantoBox">
                                        <img src="../assets/images/logomondiali.svg" alt="logo" class="impiantoImg">
                                    </div>
                                </td>
                                <td>
                                    <div class="teamBox">
                                        ---
                                        <!-- <div class="bandiera">
                                            <img :src="require('../assets/flag/'+partita.team1Id+'.png')" alt="bandiera nazionale" class="bandieraImg">
                                        </div>
                                        <div>
                                            {{partita.team1}}
                                        </div> -->
                                    </div>
                                </td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronosticiTrofei.length > 0" v-for="(giocatore, index) in pronosticiTrofei" :key="index" :class="giocatore.tag">
                        <thead>
                            <tr>
                                <th>TROFEI</th>
                                <th>PRONOSTICO</th>
                                <th>VINCITORE</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr class="roundnull">
                                <td>
                                    <b v-if="giocatore.tag === 'mvp'">MIGLIOR GIOCATORE</b>
                                    <b v-else-if="giocatore.tag === 'goalkeeper'">MIGLIOR PORTIERE</b>
                                    <b v-else-if="giocatore.tag === 'under21'">MIGLIOR UNDER21</b>
                                    <b v-else>CAPOCANNONIERE</b>
                                </td>
                                <td>
                                    <b>{{ giocatore.FifaName }}</b>
                                </td>
                                <td>
                                    <b v-if="giocatore.tag === 'mvp'">MESSI</b>
                                    <b v-else-if="giocatore.tag === 'goalkeeper'">EMILIANO MARTINEZ</b>
                                    <b v-else-if="giocatore.tag === 'under21'">ENZO FERNANDEZ</b>
                                    <b v-else>MBAPPE</b>
                                </td>
                                <td>
                                    <b v-if="giocatore.tag === 'mvp' && giocatore.isWinner">1000</b>
                                    <b v-else-if="giocatore.tag === 'mvp' && !giocatore.isWinner">---</b>
                                    <b v-if="giocatore.tag === 'goalkeeper' && giocatore.isWinner">1000</b>
                                    <b v-else-if="giocatore.tag === 'goalkeeper' && !giocatore.isWinner">---</b>
                                    <b v-if="giocatore.tag === 'under21' && giocatore.isWinner">1000</b>
                                    <b v-else-if="giocatore.tag === 'under21' && !giocatore.isWinner">---</b>
                                    <b v-if="giocatore.tag === 'topscorer' && giocatore.isWinner">1000</b>
                                    <b v-else-if="giocatore.tag === 'topscorer' && !giocatore.isWinner">---</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronosticiTrofei.length === 0" class="mvp">
                        <thead>
                            <tr>
                                <th>TROFEI</th>
                                <th>PRONOSTICO</th>
                                <th>VINCITORE</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr class="roundnull">
                                <td><b>MIGLIOR GIOCATORE</b></td>
                                <td>---</td>
                                <td>---</td>
                                <td><b>---</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronosticiTrofei.length === 0" class="goalkeeper">
                        <thead>
                            <tr>
                                <th>TROFEI</th>
                                <th>PRONOSTICO</th>
                                <th>VINCITORE</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr class="roundnull">
                                <td><b>MIGLIOR PORTIERE</b></td>
                                <td>---</td>
                                <td>---</td>
                                <td><b>---</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronosticiTrofei.length === 0" class="under21">
                        <thead>
                            <tr>
                                <th>TROFEI</th>
                                <th>PRONOSTICO</th>
                                <th>VINCITORE</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr class="roundnull">
                                <td><b>MIGLIOR UNDER21</b></td>
                                <td>---</td>
                                <td>---</td>
                                <td><b>---</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-show="pronosticiTrofei.length === 0" class="topscorer">
                        <thead>
                            <tr>
                                <th>TROFEI</th>
                                <th>PRONOSTICO</th>
                                <th>VINCITORE</th>
                                <th>PUNTI</th>
                            </tr>           
                        </thead>
                        <tbody>
                            <tr class="roundnull">
                                <td><b>CAPOCANNONIERE</b></td>
                                <td>---</td>
                                <td>---</td>
                                <td><b>---</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="quitBtn">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" @click="back()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            token: '',
            codiceConto: null,
            contoEsiste: false,
            message: "",
            success: false,
            noPronoMessage: "",
            schedule: [],
            scheduleGironi: [],
            scheduleRound16: [],
            scheduleQuarter: [],
            scheduleSemis: [],
            scheduleFinalB: [],
            scheduleFinalA: [],
            userPredictionResults: [],
            isPredictionCorrect: false,
            pronostico: {},
            pronosticiTrofei: [],
            teamBonus: {},
            loading: true,
            uTotPoints: 0,
            uPronoRight: 0,
            userBonusObj: {},
            uBonusTotPoints: 0,
            isPronoCompleted: true,
            realScheduleKnockoutStages: [],
            realScheduleRound16: [],
            realScheduleQuarter: [],
            realScheduleSemi: [],
            realScheduleFinal: []
        }
    },
    mounted() {
        console.log('@@@@@@ DAL MOUNTED PAGINA DI DEBUG @@@@@');
        console.log(this.$route.params);
        this.checkUrlParams(this.$route.params);
        
    },
    methods: {
        checkUrlParams(obj) {
            //console.log('SONO QUI NELLA PAGINA CONTENUTO');
            if(typeof obj === 'object') {
                if(obj.hasOwnProperty("conto") && obj.hasOwnProperty("token")) {
                    this.codiceConto = obj.conto;
                    /* console.log(`numeroConto => ${this.codiceConto}`);
                    console.log(`valore token codificato => ${obj.token}`); */
                    this.token = atob(obj.token);
                    //console.log(`valore token decodificato => ${this.token}`);
                    // controlli fatti posso chiamare la funzione getPronostico
                    this.getPronostico(this.codiceConto, this.token);
                } else {
                    //console.log('INCORRECT DATA');
                    this.$router.push({ name: 'home', query: {a:'true',m:'Si è verificato un errore, prego controllare i dati inseriti e riprovare'}});
                }
            } else {
                //console.log('ERROR DATA NOT OBJ');
                this.$router.push({ name: 'home', query: {a:'true',m:'Si è verificato un errore, prego controllare i dati inseriti e riprovare'}});
            }
        },
        getPronostico(conto, tkn) {
            axios.get(`${process.env.VUE_APP_API_URL}api/v1/qatar2022/pronostico/${conto}`, { params: { token: tkn } , headers: { 'qatar-debug':true }})
            .then((res) => {
                console.log('risposta alla chiamata getPronostico @@@@@@');
                console.log(res);
                if(res.data.success) {
                    console.log(res.data.data);
                    this.pronostico = res.data.data.pronostico;
                    this.pronosticiTrofei = res.data.data.pronosticiPlayers.length > 0 ? res.data.data.pronosticiPlayers : [];
                    console.log('valore res.data.data.teamBonus.team');
                    console.log(res.data.data.teamBonus.team);
                    this.teamBonus = res.data.data.teamBonus;
                    if(res.data.data.userBonus) {
                        this.uPronoRight = res.data.data.userBonus.pronostici_gironi + res.data.data.userBonus.pronostici_quarti + res.data.data.userBonus.pronostici_semifinale + res.data.data.userBonus.pronostici_finale + res.data.data.userBonus.pronostici_vincente;
                        this.userBonusObj = res.data.data.userBonus;
                    } else {
                        this.uPronoRight = 0;
                        this.userBonusObj = null;
                    }
                    this.success = res.data.success;
                } else {
                    this.success = res.data.success;
                }
            })
            .catch((err) => {
                console.error(err);
                //console.log('SONO DENTRO IL CATCH PER GESTIRE ERRORE');
                this.pronostico = null;
            })
            .finally(() => {
                if(this.success) {
                    console.log('sono qui dentro');
                    console.log(this.pronostico);
                    if(this.pronostico) {
                        const arrayTrial = Object.entries(this.pronostico.results);
                        /* console.log('valore lunghezza results @@@@@@@');
                        console.log(arrayTrial.length); */
                        if(arrayTrial.length !== 63) {
                            // pronostico incompleto
                            this.isPronoCompleted = false;
                        } else {
                            this.isPronoCompleted = true;
                        }
                        console.log(this.isPronoCompleted);
                        this.getScheduleFasiMondiale(this.pronostico);
                        this.getFinalStagesResults();
                    } else {
                       this.noPronoMessage = "Pronostico non compilato"; 
                    }
                    this.loading = false;
                    console.log('VALORE pronosticiTrofei @@@@@@@@');
                    console.log(this.pronosticiTrofei);
                    console.log('VALORE teamBonus @@@@@@@@');
                    console.log(this.teamBonus);
                } else {
                    //console.log('ERROR CONTO DOESNt EXIST');
                    this.$router.push({ name: 'home', query: {a:'true',m:'ERROR: no data available for selected account'}});
                    /* this.noPronoMessage = "Pronostico non compilato";
                    this.loading = false;
                    console.log(this.pronostico);
                    console.log('VALORE pronosticiTrofei @@@@@@@@');
                    console.log(this.pronosticiTrofei);
                    console.log('VALORE teamBonus @@@@@@@@');
                    console.log(this.teamBonus); */
                }
                //console.log('chiamata terminata da fn getPronostico');
            });
        },
        getScheduleFasiMondiale(obj) {
            /* console.log('valore oggetto passato alla funzione getScheduleFasiMondiale @@@@@');
            console.log(obj); */
            
            this.schedule = obj.schedule;
            this.userPredictionResults = Object.entries(obj.results);
            //console.log(this.schedule);
            //console.log(this.userPredictionResults);
            // devo aggiungere allo schedule anche il risultato pronosticato da utente
            
            /* this.userPredictionResults.forEach((key, risultato) => {
                console.log(key);
            }); */
            this.schedule.forEach((partita) => {
                partita.userPredictedResult = null;
                this.userPredictionResults.forEach((risultato) => {
                    if(partita.matchId == risultato[0]) {
                        /* console.log('SONO DENTRO');
                        console.log(`VALORE PARTITA.MATCHID => ${partita.matchId}, VALORE RISULTATO[0] => ${risultato[0]}`); */
                        partita.userPredictedResult = risultato[1];
                    }
                });
            });

            //console.log(this.schedule);
        

            if(this.schedule.length > 0){
                /* CREAZIONE ARRAY PARTITE GIRONI E CALCOLO PUNTI GUADAGNATI DA UTENTE PER QUESTA FASE */
                this.scheduleGironi = this.schedule.filter((partita) => {
                    return partita.stage === "gironi";
                });
                this.scheduleGironi.puntiTotaliUtente = 0;
                this.scheduleGironi.forEach((partita) => {
                    if(partita.userPredictedResult) {
                        if(partita.result) {
                            if(partita.result.toUpperCase() == partita.userPredictedResult.toUpperCase()) {
                                this.scheduleGironi.puntiTotaliUtente = this.scheduleGironi.puntiTotaliUtente + 50;
                            }
                        }
                    } else {
                        //console.log('partita del girone non compilata);
                    }  
                });
                /* CREAZIONE ARRAY PARTITE OTTAVI E CALCOLO PUNTI GUADAGNATI DA UTENTE PER QUESTA FASE */
                this.scheduleRound16 = this.schedule.filter((partita) => {
                    return partita.stage === "ottavi";
                });
                this.scheduleRound16.puntiTotaliUtente = 0;
                this.scheduleRound16.forEach((partita) => {
                    if(partita.userPredictedResult) {
                        if(partita.result) {
                            if(partita.result.toUpperCase() == partita.userPredictedResult.toUpperCase()) {
                                this.scheduleRound16.puntiTotaliUtente = this.scheduleRound16.puntiTotaliUtente + 100;
                            }
                        }
                    } else {
                        //console.log('ottavo non compilato');
                    }
                });
                /* CREAZIONE ARRAY PARTITE QUARTI E CALCOLO PUNTI GUADAGNATI DA UTENTE PER QUESTA FASE */
                this.scheduleQuarter = this.schedule.filter((partita) => {
                    return partita.stage === "quarti";
                });
                this.scheduleQuarter.puntiTotaliUtente = 0;
                this.scheduleQuarter.forEach((partita) => {
                    if(partita.userPredictedResult) {
                        if(partita.result) {
                            if(partita.result.toUpperCase() == partita.userPredictedResult.toUpperCase()) {
                                this.scheduleQuarter.puntiTotaliUtente = this.scheduleQuarter.puntiTotaliUtente + 100;
                            }
                        }
                    } else {
                        //console.log('quarto non compilato');
                    }
                });
                /* CREAZIONE ARRAY PARTITE SEMIFINALI E CALCOLO PUNTI GUADAGNATI DA UTENTE PER QUESTA FASE */
                this.scheduleSemis = this.schedule.filter((partita) => {
                    return partita.stage === "semi";
                });
                this.scheduleSemis.puntiTotaliUtente = 0;
                this.scheduleSemis.forEach((partita) => {
                    if(partita.userPredictedResult) {
                        if(partita.result) {
                            if(partita.result.toUpperCase() == partita.userPredictedResult.toUpperCase()) {
                                this.scheduleSemis.puntiTotaliUtente = this.scheduleSemis.puntiTotaliUtente + 300;
                            }
                        }
                    } else {
                        //console.log('semi non compilata');
                    }
                });
                /* CREAZIONE ARRAY PARTITE FINALE B NON ACCREDITA PUNTI */
                this.scheduleFinalB = this.schedule.filter((partita) => {
                    return partita.stage === "finaleB";
                });
                this.scheduleFinalB.puntiTotaliUtente = 0;
                /* CREAZIONE ARRAY PARTITE FINALE A E CALCOLO PUNTI GUADAGNATI DA UTENTE PER QUESTA FASE */
                this.scheduleFinalA = this.schedule.filter((partita) => {
                    return partita.stage === "finaleA";
                });
                this.scheduleFinalA.puntiTotaliUtente = 0;
                this.scheduleFinalA.forEach((partita) => {
                    if(partita.userPredictedResult) {
                        if(partita.result) {
                            if(partita.result.toUpperCase() == partita.userPredictedResult.toUpperCase()) {
                                this.scheduleFinalA.puntiTotaliUtente = this.scheduleFinalA.puntiTotaliUtente + 800;
                            }
                        }
                    } else {
                        //console.log('finale non compilata');
                    }
                });

                /* CALCOLO PUNTI TOTALI UTENTE, DA MOSTRARE ALL'INIZIO IN PAGINA */
                this.uTotPoints = this.scheduleGironi.puntiTotaliUtente + this.scheduleRound16.puntiTotaliUtente + this.scheduleQuarter.puntiTotaliUtente + this.scheduleSemis.puntiTotaliUtente + this.scheduleFinalA.puntiTotaliUtente;
                this.uTotPoints = this.formatUserTotalPoint(this.uTotPoints);
                if(this.userBonusObj) {
                    this.uBonusTotPoints = this.userBonusObj.punti_totali;
                }
                /* console.log('@@@@@@@@@@ SCHEDULE GIRONI @@@@@@@@@@@@@');
                console.log(this.scheduleGironi); */
                /* console.log('@@@@@@@@@@ SCHEDULE OTTAVI @@@@@@@@@@@@@');
                console.log(this.scheduleRound16); */
                /* console.log('@@@@@@@@@@ SCHEDULE QUARTI @@@@@@@@@@@@@');
                console.log(this.scheduleQuarter); */
                /* console.log('@@@@@@@@@@ SCHEDULE SEMIFINALI @@@@@@@@@@@@@');
                console.log(this.scheduleSemis); */
                /* console.log('@@@@@@@@@@ SCHEDULE FINALE A @@@@@@@@@@@@@');
                console.log(this.scheduleFinals); */
            }   
        },
        getFinalStagesResults() {
            axios.get(`${process.env.VUE_APP_API_URL}api/v1/qatar2022/schedule`)
            .then((res) => {
                /* console.log('valore risposta getFinalSatgesResults @@@@');
                console.log(res.data.data); */
                this.realScheduleKnockoutStages = res.data.data;
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                if(this.realScheduleKnockoutStages.length > 0) {
                    this.setRealScheduleKnockoutStages(this.realScheduleKnockoutStages);
                } else {
                    console.log('realSchedule è vuoto!');
                }
                //console.log('chiamata terminata da getFinalStagesResults');
            });
        },
        setRealScheduleKnockoutStages(obj) {
            /* console.log('valore realScheduleKnockoutStages');
            console.log(obj); */
            this.realScheduleRound16 = obj.filter((partita) => {
                return partita.stage === "ottavi";
            });
            this.realScheduleQuarter = obj.filter((partita) => {
                if(partita.stage === 'quarti') {
                    if(partita.team1 && partita.team2) {
                        return partita
                    }
                }
            });
            this.realScheduleSemi = obj.filter((partita) => {
                if(partita.stage === 'semi') {
                    if(partita.team1 && partita.team2) {
                        return partita
                    }
                }
            });
            this.realScheduleFinal = obj.filter((partita) => {
                if(partita.stage === 'finaleA') {
                    if(partita.team1 && partita.team2) {
                        return partita
                    }
                }
            });

            this.checkUserKnockoutStagesPredictions(
                this.realScheduleRound16,
                this.scheduleRound16,
                this.realScheduleQuarter,
                this.scheduleQuarter,
                this.realScheduleSemi,
                this.scheduleSemis,
                this.realScheduleFinal,
                this.scheduleFinalA
            )
        },
        checkUserKnockoutStagesPredictions(objRealRound16, objUsRound16, objRealQuarter, objUsQuarter, objRealSemi, objUsSemi, objRealFinal, objUsFinal) {
            objRealRound16.forEach(realPartita => {
                objUsRound16.forEach(usPartita => {
                    if(realPartita.team1Id === usPartita.team1Id || realPartita.team2Id === usPartita.team2Id) {
                        console.log('Siamo qui!');
                        console.log(realPartita.result);
                        console.log(usPartita.userPredictedResult);
                        if(realPartita.result === usPartita.userPredictedResult) {
                            console.log('Adesso siamo qui');
                            switch (parseInt(realPartita.result)) {
                                case 1:
                                    if(realPartita.team1Id === usPartita.team1Id) {
                                        usPartita.result = realPartita.result;
                                    } else {
                                        let realQuarterFinalists = [];
                                        let userQuarterFinalists = [];
                                        objRealQuarter.forEach(partita => {
                                            realQuarterFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        objUsQuarter.forEach(partita => {
                                            userQuarterFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        realQuarterFinalists.forEach(team => {
                                            if(usPartita.team1Id === team) {
                                                usPartita.result = realPartita.result;
                                            }
                                        });
                                    }
                                    break;
                                case 2:
                                    if(realPartita.team2Id === usPartita.team2Id) {
                                        usPartita.result = realPartita.result;
                                    } else {
                                        let realQuarterFinalists = [];
                                        let userQuarterFinalists = [];
                                        objRealQuarter.forEach(partita => {
                                            realQuarterFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        objUsQuarter.forEach(partita => {
                                            userQuarterFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        realQuarterFinalists.forEach(team => {
                                            if(usPartita.team2Id === team) {
                                                usPartita.result = realPartita.result;
                                            }
                                        });
                                    }
                                    break;
                                default:
                                    break;
                            }
                            
                        }
                    }
                });
            });
            objRealQuarter.forEach(realPartita => {
                objUsQuarter.forEach(usPartita => {
                    if(realPartita.team1Id === usPartita.team1Id || realPartita.team2Id === usPartita.team2Id) {
                        if(realPartita.result === usPartita.userPredictedResult) {
                            console.log('Adesso siamo qui');
                            switch (parseInt(realPartita.result)) {
                                case 1:
                                    if(realPartita.team1Id === usPartita.team1Id) {
                                        usPartita.result = realPartita.result;
                                    } else {
                                        let realSemiFinalists = [];
                                        let userSemiFinalists = [];
                                        objRealSemi.forEach(partita => {
                                            realSemiFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        objUsSemi.forEach(partita => {
                                            userSemiFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        realSemiFinalists.forEach(team => {
                                            if(usPartita.team1Id === team) {
                                                usPartita.result = realPartita.result;
                                            }
                                        });
                                    }
                                    break;
                                case 2:
                                    if(realPartita.team2Id === usPartita.team2Id) {
                                        usPartita.result = realPartita.result;
                                    } else {
                                        let realSemiFinalists = [];
                                        let userSemiFinalists = [];
                                        objRealSemi.forEach(partita => {
                                            realSemiFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        objUsSemi.forEach(partita => {
                                            userSemiFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        realSemiFinalists.forEach(team => {
                                            if(usPartita.team2Id === team) {
                                                usPartita.result = realPartita.result;
                                            }
                                        });
                                    }
                                    break;
                                default:
                                    break;
                            }
                            
                        }
                    }
                });
            });
            objRealSemi.forEach(realPartita => {
                objUsSemi.forEach(usPartita => {
                    if(realPartita.team1Id === usPartita.team1Id || realPartita.team2Id === usPartita.team2Id) {
                        if(realPartita.result === usPartita.userPredictedResult) {
                            console.log('Adesso siamo qui');
                            switch (parseInt(realPartita.result)) {
                                case 1:
                                    if(realPartita.team1Id === usPartita.team1Id) {
                                        usPartita.result = realPartita.result;
                                    } else {
                                        let realFinalists = [];
                                        let userFinalists = [];
                                        objRealFinal.forEach(partita => {
                                            realFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        objUsFinal.forEach(partita => {
                                            userFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        realFinalists.forEach(team => {
                                            if(usPartita.team1Id === team) {
                                                usPartita.result = realPartita.result;
                                            }
                                        });
                                    }
                                    break;
                                case 2:
                                    if(realPartita.team2Id === usPartita.team2Id) {
                                        usPartita.result = realPartita.result;
                                    } else {
                                        let realFinalists = [];
                                        let userFinalists = [];
                                        objRealFinal.forEach(partita => {
                                            realFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        objUsFinal.forEach(partita => {
                                            userFinalists.push(partita.team1Id, partita.team2Id);
                                        });
                                        realFinalists.forEach(team => {
                                            if(usPartita.team2Id === team) {
                                                usPartita.result = realPartita.result;
                                            }
                                        });
                                    }
                                    break;
                                default:
                                    break;
                            }
                            
                        }
                    }
                });
            });
            objRealFinal.forEach(realPartita => {
                objUsFinal.forEach(usPartita => {
                    if(realPartita.team1Id === usPartita.team1Id || realPartita.team2Id === usPartita.team2Id) {
                        if(realPartita.result === usPartita.userPredictedResult) {
                            console.log('Adesso siamo qui');
                            switch (parseInt(realPartita.result)) {
                                case 1:
                                    if(realPartita.team1Id === usPartita.team1Id) {
                                        usPartita.result = realPartita.result;
                                    }
                                    break;
                                case 2:
                                    if(realPartita.team2Id === usPartita.team2Id) {
                                        usPartita.result = realPartita.result;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            
                        }
                    }
                });
            });
        },
        back() {
            if(this.codiceConto) {
                this.codiceConto = null;
                this.contoEsiste = false;
                this.message = "";
                //console.log(this.codiceConto);
                this.$router.push({ name: 'home', query:{a:'true'}});
            }
            
        },
        setClass(value, pronostico, risultato, idMatch = null) {
            //value => è il valore del bottone 1, X, 2
            //pronostico => è il risultato indicato dall'utente
            //risultato => è il risultato finale della partita
            /* console.log(`VALORI IN ARRIVO ALLA FUNZIONE SETCLASS @@@@@@@ con matchID => ${idMatch}`);
            console.log('VALORE value @@@@@@@');
            console.log(value);
            console.log('VALORE pronostico @@@@@@@');
            console.log(pronostico);
            console.log('VALORE risultato @@@@@@@');
            console.log(risultato); */
            let result = {
                corretto: false,
                sbagliato: false
            };
            if(risultato) {
                switch (value) {
                    case 1:
                        if(pronostico == value && risultato == value) {
                            // prediction correct
                            this.isPredictionCorrect = true;
                            if(this.isPronoCompleted) {
                                result.corretto = true;
                            } else {
                                result.corretto = false;
                            }
                        } else {
                            if(pronostico == value) {
                                if(this.isPronoCompleted) {
                                    result.sbagliato = true;
                                } else {
                                    result.sbagliato = false;
                                }
                            }
                        }
                        break;
                    case 'X':
                        if(pronostico == value && risultato == value) {
                            // prediction correct
                            this.isPredictionCorrect = true;
                            if(this.isPronoCompleted) {
                                result.corretto = true;
                            } else {
                                result.corretto = false;
                            }
                        } else {
                            if(pronostico == value) {
                                if(this.isPronoCompleted) {
                                    result.sbagliato = true;
                                } else {
                                    result.sbagliato = false;
                                }
                            }
                        }
                        break;
                    case 2:
                        if(pronostico == value && risultato == value) {
                            // prediction correct
                            this.isPredictionCorrect = true;
                            if(this.isPronoCompleted) {
                                result.corretto = true;
                            } else {
                                result.corretto = false;
                            }
                        } else {
                            if(pronostico == value) {
                                if(this.isPronoCompleted) {
                                    result.sbagliato = true;
                                } else {
                                    result.sbagliato = false;
                                }
                            }
                        }
                        break;
                
                    default:
                        console.log('Si è verificato un errore');
                        break;
                }
            }

            return result;
        },
        setMatchResultClass(value, risultato) {
            if(value == risultato) {
                if(this.isPronoCompleted) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        knockoutStagesWT(partita) {
            const result = {
                bandiera: '',
                team: ''
            };

            if(partita.result == partita.userPredictedResult) {
                //utente ha azzecato il pronostico '../assets/flag/'+partita.team1Id+'.png'
                if(partita.result == '1') {
                    //ha vinto il team1
                    result.bandiera = require(`@/assets/flag/${partita.team1Id}.png`);
                    result.team = partita.team1;
                } else {
                    //ha vinto il team2
                    result.bandiera = require(`@/assets/flag/${partita.team2Id}.png`);
                    result.team = partita.team2;
                }
            } else {
                //utente ha sbagliato il pronostico
                //ritorniamo bandiera e nome della squadra opposta
                if(partita.result == '1') {
                    //ha vinto il team1
                    result.bandiera = require(`@/assets/flag/${partita.team1Id}.png`);
                    result.team = partita.team1;
                } else {
                    //ha vinto il team2
                    result.bandiera = require(`@/assets/flag/${partita.team2Id}.png`);
                    result.team = partita.team2;
                }
            }

            return result;
        },
        formatUserTotalPoint(number) {
            const nString = number.toString();
            let formattedNumber;
            if(nString.length === 4) {
                formattedNumber = `${nString.slice(0,1)}.${nString.slice(1)}`;
            } else {
                formattedNumber = `${nString}`;
            }

            return formattedNumber;
        }
    }
}
</script>

<style lang="scss">
.userPredictionSection {
    .quitBtn {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1100px;
        margin: auto;
    }
    .logosContainer {
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        .logoBox {
            width: 150px;
            height: 142px;
            .logoImg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .textContentContainer {
        width: 100%;
        max-width: 1100px;
        margin: auto;
        font-weight: 700;
        .title {
            font-size: 3rem;
        }
        .conto {
            font-size: 2.5rem;
        }
        .totalePunti,
        .pronosticiCorretti {
            font-size: 2rem
        }
    }
    .pronoNotCompleteBox {
        max-width: 1100px;
        width: 100%;
        background: #ca1e43;
        border: 5px solid #FFFFFF;
        border-radius: 25px;
        padding: 50px 0;
        opacity: 0.7;
        margin: 15px auto 0;
        .noPronoMessage {
            font-weight: bold;
            font-size: 2rem;
            color: #FFFFFF;
        }
    }
    .customWrapper {
        width: 100%;
        max-width: 1100px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        gap: 50px;
        .groupStageRound {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px 1px #616161;
                    margin-bottom: 5px;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .pronosContainer {
                display: flex;
                justify-content: space-around;
                .pronoBox {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    background: lightgray;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #000000;
                    box-shadow: 1px 1px 3px 1px #616161;
                }
                .active {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    color: #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .corretto {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #b2d600 !important;
                    color: #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .sbagliato {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #f70000 !important;
                    color: #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .risultatoEsatto {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #b2d600 !important;
                    color: #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

            }
            .singlePronoBox {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid #FFFFFF;
                background: #b2d600;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000000;
                box-shadow: 1px 1px 3px 1px #616161;
                margin: 0 auto;
            }
        }
        .round16 {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px 1px #616161;
                    margin-bottom: 5px;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .quarter {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px 1px #616161;
                    margin-bottom: 5px;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .semis {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px 1px #616161;
                    margin-bottom: 5px;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .finalB {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px 1px #616161;
                    margin-bottom: 5px;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .finalA {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px 1px #616161;
                    margin-bottom: 5px;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .noPronoBox {
            background: #ca1e43;
            border: 5px solid #FFFFFF;
            border-radius: 25px;
            padding: 50px 0;
            opacity: 0.7;
            .noPronoMessage {
                font-weight: bold;
                font-size: 2rem;
                color: #FFFFFF;
            }
        }
        .BONUSSQUADRA {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px 1px #616161;
                    margin-bottom: 5px;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .bsBox {
                display: flex;
                flex-direction: column;
            }
        }
        .topscorer {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .mvp {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .goalkeeper {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .under21 {
            width: 100%;
            border-radius: 10px 10px 0 0;
            border-spacing: 0;
            .teamBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bandiera {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    .bandieraImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
</style>