<template>
    <div v-show="!authDone" class="dettagliSfondo">
        <div class="alertContainer">
            <div class="logosContainer">
                <div class="logoBox">
                    <img src="../assets/images/logomondiali.svg" alt="logo 1x2" class="logoImg">
                </div>
                <div class="logoBox">
                    <img src="../assets/images/logosisalverde.png" alt="logo 1x2" class="logoImg">
                </div>
                <div class="logoBox">
                    <img src="../assets/images/logo1x2.png" alt="logo 1x2" class="logoImg">
                </div>
            </div>
            <h1 class="pt-5 pb-5">MONDIALI 2022 SISAL - PRONOSTICO</h1>
            <div class="customAlert" @keypress.enter="autentica()">
                <div class="usernameBox">
                    <InputText placeholder="username" type="text" v-model="username" />
                    <!-- <label for="username">USERNAME</label>
                    <input type="text" v-model="username" name="username"> -->
                </div>
                <div class="pwdBox">
                    <Password placeholder="password" v-model="password" toggleMask :feedback="false"></Password>
                    <!-- <label for="pwd">PASSWORD</label> -->
                    <!-- <input type="password" v-model="password" name="pwd"> -->
                </div>
                <div class="btnBox">
                    <Button label="Invia" @click="autentica()" />
                </div>
            </div>
        </div>
    </div>
    <div v-if="promptSuperato == 'true'" class="dettagliSfondo">
        <div class="contenutoPagina">
            <div v-if="!contoEsiste" class="ricercaConto">
                <div class="logosContainer">
                    <div class="logoBox">
                        <img src="../assets/images/logomondiali.svg" alt="logo 1x2" class="logoImg">
                    </div>
                    <div class="logoBox">
                        <img src="../assets/images/logosisalverde.png" alt="logo 1x2" class="logoImg">
                    </div>
                    <div class="logoBox">
                        <img src="../assets/images/logo1x2.png" alt="logo 1x2" class="logoImg">
                    </div>
                </div>
                <h1 class="pt-5">MONDIALI 2022 SISAL - PRONOSTICO</h1>
                <!-- SEZIONE RICERCA CONTO -->
                <div class="pt-5">
                    <InputText v-model="codiceConto" type="text" placeholder="#conto 16 cifre" class="mr-5" @keypress.enter="goToContentPage(codiceConto)"/>
                    <Button label="Cerca" @click="goToContentPage(codiceConto)"/>
                </div>
                <!-- SEZIONE CONTO NON PRESENTE -->
                <div v-show="message" class="mt-5" style="background: white; color: red; width: 50vw; margin:auto; padding: 20px 30px;">
                    <p><strong>{{ message }}</strong></p>
                </div>
            </div>
        </div>
    </div>
    <Toast position="top-center" />
</template>

<script>
export default {
    data() {
        return {
            authDone: false,
            username: '',
            password: '',
            mex: '',
            promptSuperato: '',
            codiceConto: null,
            contoEsiste: false,
            message: null
        }
    },
    mounted() {
        this.checkUrlToShowCorrectBox(this.$route.query)
    },
    methods: {
        checkUrlToShowCorrectBox(obj) {
            //console.log('sono dentro la funzione checkUrlToShowCorrectBox');
            if(typeof obj === 'object') {
                if(obj.hasOwnProperty("a")) {
                    // utente ha già superato il primo box di auth
                    if(obj.a === 'true') {
                        this.authDone = true;
                        this.promptSuperato = 'true';
                    } else {
                        this.authDone = false;
                        this.promptSuperato = 'false';
                    }
                    if(obj.hasOwnProperty("m")) {
                        this.message = obj.m;
                    } else {
                        this.message = "";
                    }
                    //console.log(this.authDone);
                    //console.log(this.message);
                } else {
                    if(obj.hasOwnProperty("m")) {
                        this.message = obj.m;
                    } else {
                        this.message = "";
                    }
                }
            } else {
                //console.log('obj non è un oggetto???');
            }
        },
        autentica() {
            /* console.log(this.password);
            console.log(typeof this.password);
            console.log('valore mex');
            console.log(this.mex); */
            if(!this.username || !this.password) {
                this.mex = "Attenzione compilare correttamente tutti i campi!";
                this.$toast.add({severity:'error',summary:'ERRORE',detail:this.mex,life:3000});
            } else {
                if(this.username === process.env.VUE_APP_FFU && this.password == process.env.VUE_APP_FFP) {
                    this.authDone = true;
                    this.promptSuperato = 'true';
                } else {
                    this.mex = "Attenzione compilare correttamente tutti i campi!";
                    this.$toast.add({severity:'error',summary:'ERRORE',detail:this.mex,life:3000});
                }
            }
        },
        goToContentPage(numConto) {
            if(!numConto) {
                this.message = "ERROR: inserire un numero di conto";
            } else {
                if(numConto.toString().length < 16) {
                    console.log('NON ABBIAMO PASSATO LA VALIDAZIONE DEI SEDICI CARATTERI');
                    this.message = "ERROR: no data available for selected account";
                } else {
                    const tknEncode = btoa(process.env.VUE_APP_TOKEN);
                    console.log('SONO QUI LANCIAMO LA FUNZIONE PER ANDARE NELLA PAGINA CONTENUTO');
                    this.$router.push({ name: 'mondialibackoffice', params: { conto:numConto, token:tknEncode } });
                }
            }
        }
    }
}
</script>

<style lang="scss">
.alertContainer {
    height: 100vh;
    .logosContainer {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
        margin-bottom: 20px;
        .logoBox {
            width: 150px;
            height: 142px;
            .logoImg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
.customAlert {
    width: 500px;
    padding: 50px;
    margin: 0 auto;
    border: 5px solid #ffffff;
    border-radius: 20px;
    background: #ca1e43;
    box-shadow: 2px 2px 10px 1px #616161;
    .usernameBox {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        input {
            width: 200px;
        }
        input:focus {
            border: none;
            box-shadow: none;
        }
    }
    .pwdBox {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        input {
            width: 200px;
        }
        input:focus {
            border: none;
            box-shadow: none;
        }
    }
    .btnBox {
        display: flex;
        justify-content: center;
        .p-button {
            width: 200px;
            background: #006636;
            color: white;
            border: none;
        }
        .p-button:hover {
            background: #00934e;
            color: white;
            border: none;
        }
        .p-button:focus {
            border: none;
            box-shadow: none;
        }
    }
}
.ricercaConto {
    height: 100vh;
    .logosContainer {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
        margin-bottom: 20px;
        .logoBox {
            width: 150px;
            height: 142px;
            .logoImg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .p-inputnumber {
        width: 250px;
        .p-inputtext:focus {
            border: none;
            box-shadow: none;
        }
    }
    .p-button {
        width: 100px;
        background: #006636;
        border: none;
    }
    .p-button:enabled:hover {
        background: #00934e;
    }
}
</style>